// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alimentares-js": () => import("./../../../src/pages/alimentares.js" /* webpackChunkName: "component---src-pages-alimentares-js" */),
  "component---src-pages-azul-portugal-js": () => import("./../../../src/pages/azul_portugal.js" /* webpackChunkName: "component---src-pages-azul-portugal-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lua-cheia-js": () => import("./../../../src/pages/lua_cheia.js" /* webpackChunkName: "component---src-pages-lua-cheia-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-saven-js": () => import("./../../../src/pages/saven.js" /* webpackChunkName: "component---src-pages-saven-js" */),
  "component---src-pages-vinhos-bairrada-index-js": () => import("./../../../src/pages/vinhos/bairrada/index.js" /* webpackChunkName: "component---src-pages-vinhos-bairrada-index-js" */),
  "component---src-pages-vinhos-dao-index-js": () => import("./../../../src/pages/vinhos/dao/index.js" /* webpackChunkName: "component---src-pages-vinhos-dao-index-js" */),
  "component---src-pages-vinhos-douro-index-js": () => import("./../../../src/pages/vinhos/douro/index.js" /* webpackChunkName: "component---src-pages-vinhos-douro-index-js" */),
  "component---src-pages-vinhos-index-js": () => import("./../../../src/pages/vinhos/index.js" /* webpackChunkName: "component---src-pages-vinhos-index-js" */),
  "component---src-pages-vinhos-outros-mesa-index-js": () => import("./../../../src/pages/vinhos/outros_mesa/index.js" /* webpackChunkName: "component---src-pages-vinhos-outros-mesa-index-js" */),
  "component---src-pages-vinhos-palmela-index-js": () => import("./../../../src/pages/vinhos/palmela/index.js" /* webpackChunkName: "component---src-pages-vinhos-palmela-index-js" */),
  "component---src-pages-vinhos-porto-index-js": () => import("./../../../src/pages/vinhos/porto/index.js" /* webpackChunkName: "component---src-pages-vinhos-porto-index-js" */),
  "component---src-pages-vinhos-regionais-alentejanos-index-js": () => import("./../../../src/pages/vinhos/regionais_alentejanos/index.js" /* webpackChunkName: "component---src-pages-vinhos-regionais-alentejanos-index-js" */),
  "component---src-pages-vinhos-regionais-minho-index-js": () => import("./../../../src/pages/vinhos/regionais_minho/index.js" /* webpackChunkName: "component---src-pages-vinhos-regionais-minho-index-js" */),
  "component---src-pages-vinhos-regionais-setubal-index-js": () => import("./../../../src/pages/vinhos/regionais_setubal/index.js" /* webpackChunkName: "component---src-pages-vinhos-regionais-setubal-index-js" */),
  "component---src-pages-vinhos-regionais-tejo-index-js": () => import("./../../../src/pages/vinhos/regionais_tejo/index.js" /* webpackChunkName: "component---src-pages-vinhos-regionais-tejo-index-js" */),
  "component---src-pages-vinhos-setubal-index-js": () => import("./../../../src/pages/vinhos/setubal/index.js" /* webpackChunkName: "component---src-pages-vinhos-setubal-index-js" */),
  "component---src-pages-vinhos-tejo-index-js": () => import("./../../../src/pages/vinhos/tejo/index.js" /* webpackChunkName: "component---src-pages-vinhos-tejo-index-js" */),
  "component---src-pages-vinhos-verde-index-js": () => import("./../../../src/pages/vinhos/verde/index.js" /* webpackChunkName: "component---src-pages-vinhos-verde-index-js" */),
  "component---src-pages-vinhos-vinhos-azul-portugal-index-js": () => import("./../../../src/pages/vinhos/vinhos-azul-portugal/index.js" /* webpackChunkName: "component---src-pages-vinhos-vinhos-azul-portugal-index-js" */),
  "component---src-pages-vinhos-vinhos-lua-cheia-index-js": () => import("./../../../src/pages/vinhos/vinhos-lua-cheia/index.js" /* webpackChunkName: "component---src-pages-vinhos-vinhos-lua-cheia-index-js" */),
  "component---src-wine-template-wine-template-js": () => import("./../../../src/wineTemplate/wineTemplate.js" /* webpackChunkName: "component---src-wine-template-wine-template-js" */)
}

